import React, { useEffect, useState, useRef, useMemo, useImperativeHandle, forwardRef } from "react";
import { Stage, Layer, Line, Circle, Image } from "react-konva";

const RoiDraw = forwardRef(({ mode, blob, width, height, area, setFinishState }, ref) => {
  const [image, setImage] = useState();
  const [points, setPoints] = useState([]);
  const [flattenedPoints, setFlattenedPoints] = useState([]);

  const [curMousePos, setCurMousePos] = useState([0, 0]);
  const [isMouseOverStartPoint, setMouseOverStartPoint] = useState(false);
  const [isFinished, setFinished] = useState(false);

  const [stageDimension , setStageDimension] = useState({ width: null, height: null })
  const divRef = useRef()

  const getElementSize = () => {
    return {
      width: divRef.current.offsetWidth,
      height: divRef.current.offsetHeight
    }
  }
  
  useEffect(() => {
    const checkSize = () => {
      var dimension = getElementSize()

      if (width < dimension["width"] && height < dimension["height"]) {
        dimension["width"] = width
        dimension["height"] = height
      }
      else {
        const imgAspRatio = width / height
        const screenAspRatio = dimension["width"] / dimension["height"]

        if (screenAspRatio <= imgAspRatio) {
          dimension["height"] = parseInt(dimension["width"]/(imgAspRatio))
        }
        else {
          dimension["width"] = parseInt(dimension["height"]*(imgAspRatio))
        }
      }

      setStageDimension(dimension);
    };

    checkSize()

    window.addEventListener("resize", checkSize);

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  const coordinateNorm = (coordinate) => {
    return [coordinate[0]/stageDimension["width"], coordinate[1]/stageDimension["height"]]
  }

  useEffect(() => {
    if (mode == "Modify") {
      setPoints(area.map((i) => [i[0], i[1]]))
      setFinished(true)
      setFinishState(true)
    }

    const element = new window.Image();
    element.src = URL.createObjectURL(blob);

    setImage(element);
  }, [])

  useEffect(() => {
    const cursorX = curMousePos[0]/stageDimension["width"]
    const cursorY = curMousePos[1]/stageDimension["height"]

    setFlattenedPoints(points.length > 0 ? (
      isFinished ? (
        points.map((_, idx) =>  idx < points.length-1 ? (
          [points[idx][0], points[idx][1], points[idx+1][0], points[idx+1][1]]
        ) : (
          [points[idx][0], points[idx][1], points[0][0], points[0][1]])
        )
      ) : (
        points.length > 1 ? (
          points.slice(0, points.length-1).map((_, idx) =>
            [points[idx][0], points[idx][1], points[idx+1][0], points[idx+1][1]]
          ).concat([[points[points.length-1][0], points[points.length-1][1], cursorX, cursorY]])
        ) : (
          [[points[0][0], points[0][1], cursorX, cursorY]]
        )
      )
    ) : (
      []
    ))
  }, [points, isFinished, curMousePos])

  const getMousePos = (stage) => {
    return [stage.getPointerPosition().x, stage.getPointerPosition().y];
  };

  const handleClick = (event) => {
    const stage = event.target.getStage();
    const mousePos = getMousePos(stage);
    if (isFinished) {
      return;
    }
    if (isMouseOverStartPoint && points.length >= 3) {
      setFinished(true);
      setFinishState(true)
    } else {
      setPoints([...points, coordinateNorm(mousePos)]);
    }
  };

  const handleMouseMove = (event) => {
    const stage = event.target.getStage();
    const mousePos = getMousePos(stage);
    setCurMousePos(mousePos);
  };

  const handleMouseOverStartPoint = (event) => {
    if (isFinished || points.length < 3) return;
    event.target.scale({ x: 2, y: 2 });
    setMouseOverStartPoint(true);
  };

  const handleMouseOutStartPoint = (event) => {
    event.target.scale({ x: 1, y: 1 });
    setMouseOverStartPoint(false);
  };

  const handlePointRemove = (index) => {
    if (!isFinished) {
      return
    }

    const arr = JSON.parse(JSON.stringify(points))

    if (points.length > 3) {
      arr.splice(index, 1)
      setPoints(arr)
    }
  }

  const handlePointHover = (event) => {
    if (!isFinished) {
      return
    }

    const target = event.target

    target.fill("white")
    target.stroke("red")
  }

  const handlePointExit = (event) => {
    if (!isFinished) {
      return
    }

    const target = event.target

    target.fill("white")
    target.stroke("blue")
  }

  const handlePointAdd = (index) => {
    if (!isFinished) {
      return
    }

    const arr = JSON.parse(JSON.stringify(points))
    arr.splice(index+1, 0, coordinateNorm([curMousePos[0], curMousePos[1]]))

    setPoints(arr)
  }

  const handleDragMovePoint = (event, index) => {
    var x = event.x
    var y = event.y

    if (x < 0) { x = 0 }
    if (y < 0) { y = 0 }

    if (x > stageDimension["width"]) { x = stageDimension["width"] }
    if (y > stageDimension["height"]) { y = stageDimension["height"] }

    setPoints([...points.slice(0, index), coordinateNorm([x, y]), ...points.slice(index + 1)]);

    return {
      x: x,
      y: y
    };
  }

  useImperativeHandle(ref, () => ({
    // getCoordinates: async () => {
    //   const sleep = (ms) => new Promise(r => setTimeout(r, ms))

    //   const uri = stageRef.current.toDataURL({ width: width, height: height })
    //   const blob = await (await fetch(uri)).blob()

    //   await sleep(10000)

    //   return points
    // },
    getCoordinates: () => {
      return points.map((i) => [i[0], i[1]])
    },
    reset: () => {
      switch (mode) {
        case "Draw": 
          setPoints([])
          setFinished(false)
          setFinishState(false)
          break
        case "Modify":
          setPoints(area)
          setFinished(true)
          setFinishState(true)
          break
        default:
          break
      }
    },
    clear: () => {
      setPoints([])
      setFinished(false)
      setFinishState(false)
    }
  }))

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#BEBEBE" }} ref={divRef}>
      <Stage
        width={stageDimension["width"]}
        height={stageDimension["height"]}
        onMouseDown={handleClick}
        onMouseMove={handleMouseMove}
      >
        <Layer>
          <Image
            image={image}
            x={0}
            y={0}
            width={stageDimension["width"]}
            height={stageDimension["height"]}
          />

          {flattenedPoints.map((pts, index) => {
            return (
              <Line
                points={[pts[0]*stageDimension["width"], pts[1]*stageDimension["height"], pts[2]*stageDimension["width"], pts[3]*stageDimension["height"]]}
                fill="blue"
                stroke="black"
                strokeWidth={5}
                closed={isFinished}
                onDblClick={() => handlePointAdd(index)}
              />
            )          
          })}

          {points.map((point, index) => {
            const startPointAttr = index === 0 ? {
              onMouseOver: handleMouseOverStartPoint,
              onMouseOut: handleMouseOutStartPoint
            } : null

            return (
              <Circle
                key={index}
                x={point[0]*stageDimension["width"]}
                y={point[1]*stageDimension["height"]}
                fill="white"
                stroke="blue"
                radius={7}
                strokeWidth={3}
                onMouseEnter={handlePointHover}
                onMouseLeave={handlePointExit}
                onDragStart={handlePointHover}
                onDragEnd={handlePointExit}
                onDblClick={() => handlePointRemove(index)}
                dragBoundFunc={(e) => handleDragMovePoint(e, index)}
                draggable
                {...startPointAttr}
              />
            )
          })}
        </Layer>
      </Stage>
    </div>
  )
})

export default RoiDraw