import { toast, Toaster, ToastBar } from 'react-hot-toast';
import 'react-toastify/dist/ReactToastify.css';

const NotifyToast = () => {
  return (
    <Toaster
      error position='top-center'
      toastOptions={{             
        success: {
          duration: 5000,
          style: { borderRadius: '10px', background: '#EEF2FB', color: '#637ba6' },
        },
        error: {
          duration: 10000,
          style: { borderRadius: '10px', background: '#FDEFEE', color: 'red' },
        }
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (<>
            {icon}
            {message}
          </>)}
        </ToastBar>                
      )}
    </Toaster>
  );
};

const showSuccess = (msg) => {
  toast.dismiss();
  toast.success(msg);
}

const showError = (msg) => {
  toast.dismiss();
  toast.error(msg);    
}

export { NotifyToast, showSuccess, showError };
