import React from 'react'
import { Stack } from '@mui/material'
import { FaTimes } from "react-icons/fa";
import { liveUrl } from 'Shared/utils';
import "../style.css"

const Player = ({ clearStream, item }) => {
    const url = item ? `${ liveUrl }/${item[0]}` : null
    
    return (
      <div className={"windowContainer"} style={{"width": "100%", "height": "100%"}}>
        <Stack className='streamheader' justifyContent="space-between" direction="row">
          <div className='streamTitle'>{item ? item[1] : '\u00a0'}</div>
          {(item != null) && 
            <FaTimes size={20} style={{"color": "white", "marginTop": "auto", "marginBottom": "auto"}} onClick={() => clearStream()}/>
          }
        </Stack>
      
        <div style={{"flexGrow": "1"}}>
          {url ? (
            <iframe src={url} style={{ "width": "100%", "height": "100%", "pointerEvents": "none" }} />
          ) : (
            <div style={{"width": "100%", height: "100%", backgroundColor: "black"}}></div>
          )}
        </div>
      </div>
    )
}

export default Player
