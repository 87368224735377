import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { apiUrl } from 'Shared/utils';

const CameraList = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [cameras, setCameras] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/camera/list`,
        returnType: "json"
      })

      setCameras(body.map((i) => ({
        "name": i["name"],
        "status": (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", textAlign: "center" }}>
            <span style={{ height: "15px", width: "15px", backgroundColor: i["online"] ? "green" : "red", borderRadius: "50%" }}></span>
            <span style={{ height: "15px", marginLeft: "5px", color: i["online"] ? "green" : "red" }}>{i["online"] ? "ONLINE" : "OFFLINE"}</span>
          </div>
        )
      })))

      setLoading(false)
    }

    init()
  }, [])

  return (<>
    <Container fluid className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <Row style={{flex: "1", margin: "10px"}}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={cameras} count={null}
              // onRowClick={console.log("Clicked")}
              columns={{
                "name": { "label": t("cameras_cameraName"), "sorting": false, "nowrap": true },
                "status": { "label": t("cameras_status"), "sorting": false, "nowrap": true },
                // "last_online": { "label": t("cameras_lastOnline"), "sorting": false, "nowrap": true }
              }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default CameraList