import React, { useState, useEffect, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { BiSolidSquare, BiSolidGridAlt, BiSolidGrid } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import _ from 'lodash';

import useFetchData from 'Shared/Hooks/useFetchData';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import { IconButtonComponent } from 'Shared/Components/Icon';
import { apiUrl } from 'Shared/utils';
import { cloneJson } from 'Shared/utils';
import "./style.css"

import Player from './components/Player'

const CameraLive = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [cameras, setCameras] = useState([])
  const [streamList, setStreamList] = useState([])
  const [grid, setGrid] = useState()
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCameras = async () => {
      const body = await send({
          method: "GET",
          url: `${apiUrl}/camera/list`,
          returnType: "json"
      })

      body && setCameras(body)
    }

    const init = async () => {
      await Promise.all([
        getCameras()
      ]);

      await initArray(2)

      setLoading(false)
    }

    init()
  }, [])

  const initArray = (grid) => {
    const array = Array.apply(null, {length: grid}).map(i => Array.apply(null, {length: grid}).map(i => null))
    
    setStreamList(array)
    setGrid(grid.toString())
  }

  const clearStream = (row, col) => {
    const temp = _.cloneDeep(streamList)
    temp[row][col] = null

    setStreamList(temp)
  }

  const changeGrid = (grid) => {
    initArray(grid)
  }

  const openStream = (stream) => {
    for (let [i, row] of streamList.entries()) {
      for (let [j, col] of row.entries()) {
        if (col != null) {
          if (col[0] === stream["camera_id"]) {
            return
          }
        }
      }
    }

    for (let [i, row] of streamList.entries()) {
      for (let [j, col] of row.entries()) {
        if (col == null) {
          const temp = _.cloneDeep(streamList)
          temp[i][j] = [stream["camera_id"], stream["name"]]

          setStreamList(temp)

          return
        }
      }
    }
  }

  return (<>
    <div className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <Container className="mainContainer" fluid style={{ width: "100%", height: "100%", borderRadius: "15px", backgroundColor: "white" }}>
        <Row>
          <Col>
            <Stack justifyContent="end" direction="row" spacing={2}>
              <Stack direction="row" spacing={2}>
                <IconButtonComponent title="" onClick={() => changeGrid(1)}>
                  <BiSolidSquare size={20}/>
                </IconButtonComponent>

                <IconButtonComponent title="" onClick={() => changeGrid(2)}>
                  <BiSolidGridAlt size={20}/>
                </IconButtonComponent>
                
                <IconButtonComponent title="" onClick={() => changeGrid(4)}>
                  <BiSolidGrid size={20}/>  
                </IconButtonComponent>
              </Stack>
            </Stack>
          </Col>
        </Row>

        <Row style={{ flexGrow: 1, overflow: "hidden" }}>
          <Col xs={3} style={{ height: "100%", overflow: "auto", paddingLeft: 0, paddingRight: 0 }}>
            <List style={{ padding: "0px" }}>
              {cameras.map((i) =>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => openStream(i)}>
                    <ListItemText primary={i["name"]} />
                  </ListItemButton>
                </ListItem>
              )}
            </List>
          </Col>

          <Col xs={9} style={{ height: "100%", paddingLeft: 0, paddingRight: 0 }}>
            {streamList.map((i, row) => <>
              <div className={`h-${100/grid}`} style={{ height: "100%", display: "flex", flexFlow: "row", overflow: "hidden" }}>
                {i.map((j, col) => <>
                  <div className={`w-${100/grid}`} style={{ height: "100%", overflow: "hidden" }}>
                    <Player clearStream={() => clearStream(row, col)} item={j}/>
                  </div>
                </>)}
              </div>
            </>)}
          </Col>
        </Row>
      </Container> 

      <LoadingScreen isOpen={loading} />
    </div>
  </>)
}

export default CameraLive