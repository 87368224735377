import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import RoiDraw from "./components/RoiDraw";

import useWindowSize from "Shared/Hooks/useWindowSize";
import useFetchData from "Shared/Hooks/useFetchData";
import { apiUrl } from "Shared/utils";

const AIDetectionDetail = () => {
  const { camera_id } = useParams()
  const windowSize = useWindowSize()
  const { send } = useFetchData()

  const [image, setImage] = useState()
  const [roi, setRoi] = useState()
  const [finished, setFinished] = useState(false)

  const mapRef = useRef()

  const fetchImage = async () => {
    const blob = await send({
      method: "GET",
      url: `${apiUrl}/camera/snapshot`,
      params: {
        "camera_id": camera_id
      },
      returnType: "json"
    })

    if (blob == null) {
      return
    }

    const bmp = await createImageBitmap(blob);
    const { width, height } = bmp;

    setImage({
      "blob": blob,
      "width": width,
      "height": height
    })

    // onsizeChange()
  }

  const fetchRoi = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/camera/roi`,
      params: {
        "camera_id": camera_id
      },
      returnType: "json"
    })

    if (body == null) {
      return
    }

    setRoi(body)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        fetchImage(),
        fetchRoi()
      ]);
    }

    init()
  }, [])

  const reset = () => {
    mapRef.current.reset()
  }

  const clear = () => {
    mapRef.current.clear()
  }

  const update = async () => {
    var area = await mapRef.current.getCoordinates()
    area = area.map((i) => [parseFloat(i[0].toFixed(5)), parseFloat(i[1].toFixed(5))])

    await send({
      method: "POST",
      url: `${apiUrl}/camera/roi/${camera_id}`,
      contentType: "json",
      body: JSON.stringify({
        "area": JSON.stringify(area)
      }),
    })
  }
  
  return (<>
    {(image && roi) &&
      <div style={{ height: windowSize["height"], width: windowSize["width"], display: "flex", flexFlow: "column" }}>
        <div style={{ flexGrow: 1, overflow: "auto" }}>
          {image && 
            <RoiDraw
              mode={(roi.length > 0) ? "Modify" : "Draw"} ref={mapRef}
              blob={image["blob"]} width={image["width"]} height={image["height"]}
              area={roi ?? []} setFinishState={(finished) => setFinished(finished)}
            />
          }
        </div>

        <div>Current mode: {finished === true ? "Modify": "Draw"}</div>

        <button onClick={() => update(mapRef.current.getCoordinates())}>Apply</button>
        <button onClick={() => reset()}>Reset</button>
        <button onClick={() => clear()}>Clear</button>
      </div>
    }
  </>);
}

export default AIDetectionDetail