import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const AssetCertificates = () => {
    const { t, i18n } = useTranslation()
    const { organization, project } = useParams()
    const { send } = useFetchData()
    const navigate = useNavigate()

    const [equipments, setEquipments] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getCertificates = async () => {
            const body = await send({
                method: "GET",
                url: `${apiUrl}/assets_tracking/certificates`,
                returnType: "json"
            })

            body && setEquipments(body)
        }

        const init = async () => {
            await Promise.all([
                getCertificates()
            ]);

            setLoading(false)

        }

        init()
    }, [])

    return (<>
        <Container fluid className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
            <Row style={{flex: "1", margin: "10px"}}>
                <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
                    <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
                        <DataTable
                            data={equipments} count={null}
                            // onRowClick={console.log("Clicked")}
                            columns={{
                                "equipment_name": { "label": t("assetsTracking_equipmentName"), "sorting": false, "nowrap": true },
                                "certificate_name": { "label": t("assetsTracking_certificateName"), "sorting": false, "nowrap": true },
                                "start_time": { "label": t("assetsTracking_startTime"), "sorting": false, "nowrap": true },
                                "end_time": { "label": t("assetsTracking_endTime"), "sorting": false, "nowrap": true },
                                "status": { "label": t("assetsTracking_status"), "sorting": false, "nowrap": true },
                            }}
                        />
                    </Paper>
                </Col>
            </Row>
        </Container> 
        <LoadingScreen isOpen={loading} />
  </>)
}

export default AssetCertificates