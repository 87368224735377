import React, { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Stage, Layer, Line, Circle, Image, Rect, Label, Text, Tag, Group } from "react-konva";
import { IoIosClose } from "react-icons/io";
import { Divider, Paper, Stack } from '@mui/material';
import _ from 'lodash';

const Floorplan = ({ zones, data, floorplan }) => {
  const { t, i18n } = useTranslation()

  const [hovered, setHovered] = useState({})
  const [opened, setOpened] = useState({})
  const [image, setImage] = useState()

  const [curMousePos, setCurMousePos] = useState([0, 0]);
  const [stageDimension , setStageDimension] = useState({ "width": null, "height": null })
  const [imageDimension , setImageDimension] = useState({ "width": null, "height": null })
  const [dragOffset , setDragOffset] = useState({ "x": null, "y": null })
  const [stageTransformation , setStageTransformation] = useState({ "scale": 1, "x": 0, "y": 0 })

  const divRef = useRef()

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.1;
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale
    };

    var newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if (newScale <= 1) { newScale = 1 }

    var newX = -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale
    var newY = -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale

    setStageTransformation({
      "scale": newScale,
      "x": newX,
      "y": newY,
    })
  };

  const handleMouseMove = (event) => {
    const getMousePos = (stage) => {
      return [stage.getPointerPosition().x, stage.getPointerPosition().y];
    };

    const stage = event.target.getStage();
    const mousePos = getMousePos(stage);

    setCurMousePos(mousePos);

    find: {
      for (const z of zones) {
        const area = z["area"].map((i) => [
          (i[0]*imageDimension["width"])*stageTransformation["scale"] + stageTransformation["x"] + dragOffset["x"]*stageTransformation["scale"],
          (i[1]*imageDimension["height"])*stageTransformation["scale"] + stageTransformation["y"] + dragOffset["y"]*stageTransformation["scale"],
        ])
  
        if (inside([mousePos[0], mousePos[1]], area)) {
          setHovered(z)

          break find
        }
      }
      setHovered({})
    }
  };

  const inside = (point, vs) => { 
    var x = point[0], y = point[1];
    
    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        var xi = vs[i][0], yi = vs[i][1];
        var xj = vs[j][0], yj = vs[j][1];
        
        var intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
    }
    
    return inside;
  };

  useEffect(() => {
    const element = new window.Image();
    element.src = URL.createObjectURL(floorplan["blob"]);

    setImage(element)
  }, [])

  useEffect(() => {
    const getElementSize = () => {
      return {
        width: divRef.current.offsetWidth,
        height: divRef.current.offsetHeight
      }
    }

    const checkSize = () => {
      var stageDim = getElementSize()

      setStageDimension(stageDim);

      const imageDim = { width: null, height: null }

      if (floorplan["width"] < stageDim["width"] && floorplan["height"] < stageDim["height"]) {
        imageDim["width"] = floorplan["width"]
        imageDim["height"] = floorplan["height"]
      }
      else {
        const imgAspRatio = floorplan["width"] / floorplan["height"]
        const screenAspRatio = stageDim["width"] / stageDim["height"]

        if (screenAspRatio <= imgAspRatio) {
          imageDim["height"] = parseInt(stageDim["width"]/(imgAspRatio))
          imageDim["width"] = stageDim["width"]
        }
        else {
          imageDim["width"] = parseInt(stageDim["height"]*(imgAspRatio))
          imageDim["height"] = stageDim["height"]
        }
      }

      setImageDimension(imageDim)
    };

    checkSize()

    window.addEventListener("resize", checkSize);

    return () => {
      window.removeEventListener("resize", checkSize);
    };
  }, []);

  const show = () => {
    if (hovered["zone_id"] != null) {
      setOpened(Object.assign({}, {"workers": data.filter((d) => d["zone_id"] == hovered["zone_id"])}, hovered))
    }
    else {
      setOpened({})
    }
  }

  const move = (e) => {
    var x = e.x
    var y = e.y

    if (x > 0) { x = 0 }
    if (y > 0) { y = 0 }

    if (imageDimension["width"] * (stageTransformation["scale"]-1) * (-1) > x) { x = imageDimension["width"] * (stageTransformation["scale"]-1) * (-1) }
    if (imageDimension["height"] * (stageTransformation["scale"]-1) * (-1) > y) { y = imageDimension["height"] * (stageTransformation["scale"]-1) * (-1) }

    return {
      x: x,
      y: y
    };
  }

  const moveEnd = (e) => {
    setDragOffset({"x": e.target.attrs.x, "y": e.target.attrs.y})
  }

  const selected = zones.filter((z) => hovered["zone_id"] === z["zone_id"])
  
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#BEBEBE" }} ref={divRef}>
      <Stage
        width={imageDimension["width"]} height={imageDimension["height"]}
        onWheel={handleWheel} onMouseMove={handleMouseMove} onClick={show}
        scaleX={stageTransformation["scale"]} scaleY={stageTransformation["scale"]}
        x={stageTransformation["x"]} y={stageTransformation["y"]}
      >
        <Layer draggable dragBoundFunc={move} onDragEnd={moveEnd}>
          <Image image={image} x={0} y={0} width={imageDimension["width"]} height={imageDimension["height"]} />
          
          {zones.filter((z) => hovered["zone_id"] !== z["zone_id"]).map((z) => 
            <Line
              points={z["area"].map((i) => [i[0]*imageDimension["width"], i[1]*imageDimension["height"]]).flat()}
              stroke={(hovered["zone_id"] == z["zone_id"]) ? "blue" : "green"}
              strokeWidth={2}
              strokeScaleEnabled={false}
              closed={true}
            />
          )}

          {(selected.length > 0) &&
            <Line
              points={selected[0]["area"].map((i) => [i[0]*imageDimension["width"], i[1]*imageDimension["height"]]).flat()}
              stroke="blue"
              strokeWidth={2}
              strokeScaleEnabled={false}
              closed={true}
            />
          }

          {hovered["zone_name"] && 
            <Group x={(curMousePos[0]+10)/stageTransformation["scale"]-stageTransformation["x"]/stageTransformation["scale"]-dragOffset["x"]} y={(curMousePos[1]+10)/stageTransformation["scale"]-stageTransformation["y"]/stageTransformation["scale"]-dragOffset["y"]}>
            <Label scaleX={1/stageTransformation["scale"]} scaleY={1/stageTransformation["scale"]}>
              <Tag fill="grey"/>
                <Text
                  text={hovered["zone_name"]}
                  fontSize={10}
                  padding={3}
                  fill='white'
                />
              </Label>
            </Group>
          }
        </Layer>
      </Stage>

      {(opened["zone_id"] != null) && 
        <div style={{ height: stageDimension["height"]*0.8, width: stageDimension["width"]*0.4, position: "absolute", right: 30 }}>
          <Paper elevation={3} style={{ height: "100%", width: "100%", padding: "10px", display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <IoIosClose onClick={() => setOpened({})}/>
            </div>

            <Stack spacing={2} style={{ overflow: "auto" }}>
              <div>
                <div style={{ fontSize: "12px" }}>Zone Name: {opened["zone_name"]}</div>
                <div style={{ fontSize: "12px" }}>Zone Description: {opened["zone_description"]}</div>
              </div>

              {(opened["workers"].length > 0) && <>
                <Divider />

                <div>
                  <Stack spacing={2}  divider={<Divider orientation="orizontal" flexItem />}>
                    {opened["workers"].map((worker) => <div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_worker")}: {worker["worker_name"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_betteryLevel")}: {worker["battery_level"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_bodyTemp")}: {worker["body_temp"]}</div>
                      <div style={{ fontSize: "12px" }}>{t("smartwatch_heartRate")}: {worker["heart_rate"]}</div>
                      <div style={{ width: "fit-content" }}>{worker["alerts"]}</div>
                    </div>)}
                  </Stack>
                </div>
              </>}
            </Stack>
          </Paper>
        </div>
      }
    </div>
  )
}

export default Floorplan
