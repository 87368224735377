
import React from 'react';
import { Form, Input, InputNumber, Select, Radio, DatePicker, TimePicker, Checkbox } from 'antd';
import dayjs from 'dayjs';

const customizeRequiredMark = (label, { required }) => {
    return (<>
        {label}{required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </>)
}

const TextInput = ({ field_id, field_name, required, disabled }) => {
    return (
        <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
            <Input disabled={disabled} />
        </Form.Item>
    )
}

const TextArea = ({ field_id, field_name, required, disabled }) => {
    return (
        <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
            <Input.TextArea disabled={disabled}   />
        </Form.Item>
    )
} 

const NumberInput = ({ field_id, field_name, required, disabled }) => {
    return (
        <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
            <InputNumber style={{ width: "100%" }}  disabled={disabled} />
        </Form.Item>
    )
}

const Dropdown = ({ field_id, field_name, required, disabled, props }) => {
    return (
        <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
          <Select mode={props["multiple"] ? "multiple" : ""} options={props["options"]} disabled={disabled} />
        </Form.Item>
    )
} 

const CheckList = ({ field_id, field_name, required, disabled, props }) => {
    return (
        <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
            {props["multiple"] ? (
                <Checkbox.Group style={{ width: "100%" }} options={props["options"]} disabled={disabled} />
            ) : (
                <Radio.Group style={{ width: "100%" }} options={props["options"]} disabled={disabled} />
            )}
        </Form.Item>
    )
} 

const DatetimePicker = ({ field_id, field_name, required, disabled, props }) => {
    switch(props.type){
        case "date":
            return (
                <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
                    <DatePicker
                        style={{ width: "100%" }} disabled={disabled} showToday={false}
                        minDate={props["min"] ? dayjs(props["min"]) : null}
                        maxDate={props["max"] ? dayjs(props["max"]) : null}
                    />
                </Form.Item>
            )
        case "time":
            return (
                <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
                    <TimePicker style={{ width: "100%" }} disabled={disabled} format='HH:mm' showToday={false}/>
                </Form.Item>
            )
        case "datetime":
            return (
                <Form.Item label={field_name} name={field_id} rules={[ { required: (required && !disabled), message: `${field_name} is required` } ]}>
                    <DatePicker
                        showTime style={{ width: "100%" }} disabled={disabled} format='YYYY-MM-DD HH:mm' showToday={false}
                        minDate={props["min"] ? dayjs(props["min"]) : null}
                        maxDate={props["max"] ? dayjs(props["max"]) : null}
                    />
                        
                </Form.Item>
            )
        default:
            return <div></div>
    }
}

const SelectField = (props) => {
    switch (props["props"]["type"]) {
        case "checklist":
            return <CheckList {...props} />
        case "dropdown":
            return <Dropdown {...props} />
        default:
            return <div></div>
    }
}

export { TextInput, TextArea, NumberInput, SelectField, Dropdown, DatetimePicker, CheckList, customizeRequiredMark }
