import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const VRTrainingRecord = () => {
    const { t, i18n } = useTranslation()
    const { organization, project } = useParams()
    const { send } = useFetchData()
    const navigate = useNavigate()

    const [traingRecord, setTraingRecord] = useState([])
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const getTrainingRecord = async () => {
            const body = await send({
                method: "GET",
                url: `${apiUrl}/vr_training/list`,
                returnType: "json"
            })

            body && setTraingRecord(body)
        }

        const init = async () => {
            await Promise.all([
                getTrainingRecord()
            ]);

            setLoading(false)

        }

        init()
    }, [])
  
    return (<>
        <Container fluid className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
            <Row style={{flex: "1", margin: "10px"}}>
                <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
                    <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
                        <DataTable
                            data={traingRecord} count={null}
                            // onRowClick={console.log("Clicked")}
                            columns={{
                                "training_date": { "label": t("vrTraining_trainingDate"), "sorting": false, "nowrap": true },
                                "worker_name": { "label": t("vrTraining_workerName"), "sorting": false, "nowrap": true },
                                "training_module": { "label": t("vrTraining_trainingModule"), "sorting": false, "nowrap": true },
                            }}
                        />
                    </Paper>
                </Col>
            </Row>
        </Container> 
        <LoadingScreen isOpen={loading} />
    </>)
}

export default VRTrainingRecord