import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { MdCenterFocusStrong, MdOutlineSos, MdCrisisAlert, MdOutlineRoute } from 'react-icons/md';
import { FaTemperatureHigh, FaHeartbeat } from "react-icons/fa";

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import useLoop from 'Shared/Hooks/useLoop';
import { IconComponent } from 'Shared/Components/Icon';
import { apiUrl } from 'Shared/utils';
import MapDisplay from './components/MapDisplay';
import LoadingScreen from 'Shared/Components/LoadingScreen';

const SmartwatchOutdoor = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const mapRef = useRef(null);
  const [loading, setLoading] = useState(true);

  const getAlertIcons = (data) => {
    const alertIcons = {
      "sos": {
        "text": "smartwatch_sos",
        "icon": <MdOutlineSos />
      },
      "danger_zone": {
        "text": "smartwatch_intrusion",
        "icon": <MdCrisisAlert />
      },
      "high_temp": {
        "text": "smartwatch_highTemp",
        "icon": <FaTemperatureHigh />
      },
      "abnormal_heart_rate": {
        "text": "smartwatch_abnormalHeartRate",
        "icon": <FaHeartbeat />
      }
    }

    const activeWarning = Object.keys(alertIcons).filter((i) => data[i] == true)

    if (activeWarning.length === 0) {
      return null
    }
    
    return (
      <Stack justifyContent="center" direction="row" spacing={2}>
        {activeWarning.map((i) =>
          <IconComponent title={t(alertIcons[i]["text"])}>
            {alertIcons[i]["icon"]}
           </IconComponent>
        )}
      </Stack>
    )
  }

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/latest/outdoor`,
      returnType: "json"
    })

    if (body) {
      setData(
        body.features.map((i) => ({
          "device_id": i["properties"]["device_id"],
          "worker_id": i["properties"]["worker_id"],
          "worker_name": i["properties"]["worker_name"],
          "battery_level": i["properties"]["battery_level"],
          "body_temp": i["properties"]["body_temp"],
          "heart_rate": i["properties"]["heart_rate"],
          "sos": i["properties"]["sos"],
          "online": i["properties"]["online"] ? "Yes": "No",
          "alerts": getAlertIcons(i["properties"]),
        }))
      )

      mapRef.current.updateData(body)
    }
  }

  useEffect(() => {
    const init = async () => {
      await loadData()

      setLoading(false)
    }

    if (!loading) {
      mapRef.current.closePopup()
      setLoading(true)
    }

    init()
  }, [i18n.language])

  const focus = (id, zoom) => {
    const feature = mapRef.current.getFeature(id)

    const content = `
      <div style="font-size: 0.8em">
        ${t("smartwatch_worker")}: ${feature.get("worker_name") ? feature.get("worker_name") : "-"}<br />
        ${t("smartwatch_betteryLevel")}: ${feature.get("battery_level") ? feature.get("battery_level") : "-"}<br />
        ${t("smartwatch_bodyTemp")}: ${feature.get("body_temp") ? feature.get("body_temp") : "-"}<br />
        ${t("smartwatch_heartRate")}: ${feature.get("heart_rate") ? feature.get("heart_rate") : "-"}<br />
      </div>
    `

    mapRef.current.zoomToFeature(id, feature, content, zoom)
  }

  useLoop(loadData, 10000)

  return (<>
    <Container fluid className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <Row style={{"height": "50%", margin: "10px 10px 5px 10px"}}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <MapDisplay focus={focus} ref={mapRef}/>
          </Paper>
        </Col>
      </Row>

      <Row style={{"height": "50%", margin: "5px 10px 10px 10px"}}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={data} count={null}
              // onRowClick={console.log("Clicked")}
              columns={{
                "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                "battery_level": { "label": t("smartwatch_betteryLevel"), "sorting": false, "nowrap": true },
                "body_temp": { "label": t("smartwatch_bodyTemp"), "sorting": false, "nowrap": true },
                "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                "online": { "label": t("smartwatch_online"), "sorting": false, "nowrap": true },
                "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true }
              }}
              actions={[
                {icon: <MdCenterFocusStrong/>, text: "Locate Worker", onClick: function(row) { focus(row["device_id"], true) }},
              ]}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchOutdoor