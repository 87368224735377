import React, { useEffect, useState, useRef } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography, Stack } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row } from 'react-bootstrap'
import { MdCenterFocusStrong, MdOutlineSos, MdCrisisAlert, MdOutlineRoute } from 'react-icons/md';
import { FaTemperatureHigh, FaHeartbeat } from "react-icons/fa";
import { Stage, Layer, Line, Circle, Image, Rect } from "react-konva";

import Floorplan from './components/Floorplan';

import useFetchData from 'Shared/Hooks/useFetchData';
import DataTable from 'Shared/Components/DataTable';
import { IconComponent } from 'Shared/Components/Icon';
import { apiUrl } from 'Shared/utils';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import moment from 'moment';

const SmartwatchConfinedSpace = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [data, setData] = useState([])
  const [zones, setZones] = useState([])
  const [floorplan, setFloorplan] = useState()
  const [loading, setLoading] = useState(true);

  const getAlertIcons = (data) => {
    const alertIcons = {
      "sos": {
        "text": "smartwatch_sos",
        "icon": <MdOutlineSos />
      },
      "danger_zone": {
        "text": "smartwatch_intrusion",
        "icon": <MdCrisisAlert />
      },
      "high_temp": {
        "text": "smartwatch_highTemp",
        "icon": <FaTemperatureHigh />
      },
      "abnormal_heart_rate": {
        "text": "smartwatch_abnormalHeartRate",
        "icon": <FaHeartbeat />
      }
    }

    const activeWarning = Object.keys(alertIcons).filter((i) => data[i] == true)

    if (activeWarning.length === 0) {
      return null
    }
    
    return (
      <Stack justifyContent="center" direction="row" spacing={2}>
        {activeWarning.map((i) =>
          <IconComponent title={t(alertIcons[i]["text"])}>
            {alertIcons[i]["icon"]}
           </IconComponent>
        )}
      </Stack>
    )
  }

  const loadData = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/latest/confined_space`,
      returnType: "json"
    })

    if (body) {
      setData(
        body.map((i) => ({
          "device_id": i["device_id"],
          "worker_id": i["worker_id"],
          "worker_name": i["worker_name"],
          "battery_level": i["battery_level"],
          "body_temp": i["body_temp"],
          "heart_rate": i["heart_rate"],
          "zone_id": i["zone"],
          "zone_name": i["zone_name"],
          "sos": i["sos"],
          "online": i["online"] ? "Yes": "No",
          "alerts": getAlertIcons(i),
          "last_update": i.last_update != null ? moment(i.last_update).format("YYYY-MM-DD HH:mm:SS") : null
        }))
      )
    }
  }

  const fetchFloorPlan = async () => {
    const blob = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/floorplan`,
      returnType: "blob"
    })

    const bmp = await createImageBitmap(blob);
    const { width, height } = bmp;

    setFloorplan({
      "blob": blob,
      "width": width,
      "height": height
    })
  }

  const fetchZones = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/zones`,
      returnType: "json"
    })

    setZones(body)
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        loadData(),
        fetchFloorPlan(),
        fetchZones()
      ]);

      setLoading(false)
    }

    init()    
  }, [])

  return (<>
    <Container fluid className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <Row style={{height: "50%", margin: "10px 10px 5px 10px"}}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          {floorplan && 
            <Floorplan zones={zones} data={data} floorplan={floorplan}/>
          }
        </Col>
      </Row>

      <Row style={{height: "50%", margin: "5px 10px 10px 10px"}}>
        <Col xs={12} style={{ height: "100%", overflow: "auto", borderRadius: "15px", paddingLeft: 0, paddingRight: 0}}>
          <Paper elevation={0} sx={{ height: "100%", display: "flex", flexFlow: "column" }}>
            <DataTable
              data={data} count={null}
              // onRowClick={() => console.log("Clicked")}
              columns={{
                "worker_name": { "label": t("smartwatch_worker"), "sorting": false, "nowrap": true },
                "battery_level": { "label": t("smartwatch_betteryLevel"), "sorting": false, "nowrap": true },
                "body_temp": { "label": t("smartwatch_bodyTemp"), "sorting": false, "nowrap": true },
                "heart_rate": { "label": t("smartwatch_heartRate"), "sorting": false, "nowrap": true },
                "online": { "label": t("smartwatch_online"), "sorting": false, "nowrap": true },
                "zone_name": { "label": t("smartwatch_zone"), "sorting": false, "nowrap": true },
                "alerts": { "label": t("smartwatch_alerts"), "sorting": false, "nowrap": true },
                "last_update": { "label": t("smartwatch_lastUpdate"), "sorting": false, "nowrap": true } 
              }}
            />
          </Paper>
        </Col>
      </Row>
    </Container> 

    <LoadingScreen isOpen={loading} />
  </>)
}

export default SmartwatchConfinedSpace