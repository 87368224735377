import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import 'moment/locale/zh-hk'

import { MdLanguage } from "react-icons/md";
import { IoSettingsSharp, IoRainy } from "react-icons/io5";
import { FaTemperatureFull } from "react-icons/fa6";
import { IoIosLogOut, IoIosWater } from "react-icons/io";
import { PiList } from "react-icons/pi";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import useLoop from 'Shared/Hooks/useLoop';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';

const Appbar = ({ projects, projectInfo, setAppbarLoading }) => {
  const { user, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [weatherInfo, setWeatherInfo] = useState()
  const [weatherWarning, setWeatherWarning] = useState()
  const [time, setTime] = useState()
  const [icon, setIcon] = useState()
  const [favicon, setFavicon] = useState()
  const [anchorEl, setAnchorEl] = useState(null);

  const [projectModelOpen, setProjectModelOpen] = useState(false)
  const [languageModelOpen, setLanguageModelOpen] = useState(false)

  const getWeather = async () => {
    await Promise.all([
      send({ url: `${apiUrl}/weather/info`, method: "GET", returnType: "json" }).then(res =>
        res && setWeatherInfo(res)
      ),
      send({ url: `${apiUrl}/weather/warning`, method: "GET", returnType: "json" }).then(res =>
        res && setWeatherWarning(res)
      )
    ]);
  }

  const getTime = () => {
    setTime(moment(new Date()))
  }

  const getIcon = async () => {
    await Promise.all([
      send({ url: `${apiUrl}/orginization/icon/${organization}`, method: "GET", returnType: "blob" }).then(res =>
        res && setIcon(URL.createObjectURL(res))
      ),
      send({ url: `${apiUrl}/orginization/favicon/${organization}`, method: "GET", returnType: "blob" }).then(res =>
        res && setFavicon(URL.createObjectURL(res))
      )
    ]);
  }

  useEffect(() => {
    const init = async () => {
      await Promise.all([
        getWeather(),
        getTime(),
        getIcon(),
      ]);

      setAppbarLoading(false)
    }

    init()
  }, [])

  useLoop(getTime, 1000)
  useLoop(getWeather, 600000)

  const selectProject = (project) => {
    setProjectModelOpen(false)
    navigate(`/${organization}/${project}`)
  }

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang)
    setLanguageModelOpen(false)
  }

  return (<>
    <Stack justifyContent="space-between" direction="row" spacing={5} style={{ "height": "100%", "width": "100%" }}>
      <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ display: { xs: 'none', lg: 'flex' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
          <img src={icon} style={{ height: "80%"}} onClick={() => navigate(".")}/>
        </Box>

        <Box sx={{ display: { xs: 'flex', lg: 'none' }, height: "100%", justifyContent: "center", alignItems: "center" }}>
          <img src={favicon} style={{ height: "80%"}} onClick={() => navigate(".")}/>
        </Box>
      </div>

      {(project && projectInfo) &&
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography sx={{ display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2 }} variant="body1">
            {projectInfo[i18n.language.toLowerCase().substring(0, 2)]}
          </Typography>
        </div>
      }

      <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
        <Box sx={{ height: "100%", display: { xs: 'none', md: 'flex' }, flexDirection: 'column' , justifyContent: "center", alignItems: "center" }}>
          <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {time &&
              <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>{time.locale(i18n.language.toLowerCase()).format("LLLL")}</div>
            }
          </div>

          <div style={{ height: "50%", display: "flex", justifyContent: "center", alignItems: "center" }}>

            <Stack justifyContent="space-between" direction="row" spacing={2} style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
              {weatherInfo && <>
                <img src={weatherInfo["icon"]} style={{ height: "20px"}} />
                  
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <FaTemperatureFull size={20} /> {weatherInfo["temperature"]}°C
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <IoRainy size={20} /> {weatherInfo["rainfall"]}mm
                </div>

                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", whiteSpace: "nowrap" }}>
                  <IoIosWater size={20} /> {weatherInfo["humidity"]}%
                </div>
              </>}

              {weatherWarning && <>
                {weatherWarning.map((i) => (
                  <img src={i} style={{ height: "20px"}} />
                ))}
              </>}
            </Stack>
          </div>
        </Box>

        <IconButtonComponent title={t("general_project")} onClick={() => setProjectModelOpen(true)}>
          <PiList size={20}/>
        </IconButtonComponent>

        <IconButtonComponent title={t("general_settings")} onClick={(event) => setAnchorEl(event.currentTarget)}>
          <IoSettingsSharp size={20}/>
        </IconButtonComponent>
      </Stack>
    </Stack>

    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} transformOrigin={{ horizontal: 'right' }} MenuListProps={{ sx: { py: 0 } }}>
      <List style={{ padding: "0px" }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar src={user.picture} />
          </ListItemAvatar>
          <ListItemText primary={user.name} secondary={user.email}/>
        </ListItem>

        <Divider sx={{ bgcolor: "secondary.light" }}/>    

        <ListItem disablePadding>
          <ListItemButton onClick={() => { setLanguageModelOpen(true); setAnchorEl(null); }}>
            <ListItemIcon>
              <MdLanguage size={20} />
            </ListItemIcon>
            <ListItemText primary={t("general_language")}/>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding>
          <ListItemButton onClick={() =>{ logout({ logoutParams: { returnTo: webUrl } }); setAnchorEl(null); }}>
            <ListItemIcon>
              <IoIosLogOut size={20}/>
            </ListItemIcon>
            <ListItemText primary={t("general_logout")}/>
          </ListItemButton>
        </ListItem>
      </List>          
    </Menu>

    <PopupModal maxHeight="80%" width="80%" isOpen={projectModelOpen} toggle={() => setProjectModelOpen(false)}>
      <List style={{ padding: "0px" }}>
        {(projects ?? []).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={`${i[i18n.language.toLowerCase().substring(0, 2)]}`} onClick={() => selectProject(`${i["project_code"]}`)} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </PopupModal>

    <PopupModal maxHeight="80%" width="80%" isOpen={languageModelOpen} toggle={() => setLanguageModelOpen(false)}>
      <List style={{ padding: "0px" }}>
        {([t("general_languageEn"), t("general_languageZh")]).map((i) => 
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={t(i)} onClick={() => changeLanguage(i)} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </PopupModal>
  </>)
}

export default Appbar