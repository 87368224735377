import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom'
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListItemAvatar, Avatar, Menu, Stack, Typography, Paper } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import moment from 'moment';
import 'moment/locale/zh-hk'

import { HiSignal, HiSignalSlash } from "react-icons/hi2";
import { IoIosAlert } from "react-icons/io";
import { MdOutlinePending } from "react-icons/md";
import { FiCheckCircle } from "react-icons/fi";
import { IoClose } from "react-icons/io5";
import { MdOutlineTimerOff } from "react-icons/md";

import { IconButtonComponent } from 'Shared/Components/Icon';
import PopupModal from 'Shared/Components/PopupModal';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import useLoop from 'Shared/Hooks/useLoop';
import useWindowSize from 'Shared/Hooks/useWindowSize';
import useFetchData from 'Shared/Hooks/useFetchData';
import { apiUrl, webUrl } from 'Shared/utils';

const Dashboard = () => {
  const { user, logout } = useAuth0()
  const { organization, project } = useParams()
  const { t, i18n } = useTranslation()
  const { send } = useFetchData()
  const navigate = useNavigate()
  const { projectInfo } = useOutletContext()

  const [loading, setLoading] = useState(true);

  const [reportConfnedSpaceSmartwatch, setReportConfnedSpaceSmartwatch] = useState()
  const [reportOutdoorSmartwatch, setReportOutdoorSmartwatch] = useState()
  const [reportCameras, setReportCameras] = useState()
  const [reportPtw, setReportPtw] = useState()

  const getReportConfnedSpaceSmartwatch = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/report/confined_space`,
      returnType: "json"
    })

    setReportConfnedSpaceSmartwatch(body)
  }

  const getReportOutdoorSmartwatch = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/smartwatch/report/outdoor`,
      returnType: "json"
    })

    setReportOutdoorSmartwatch(body)
  }

  const getReportCameras = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/camera/report`,
      returnType: "json"
    })

    setReportCameras(body)
  }

  const getReportPtw = async () => {
    const body = await send({
      method: "GET",
      url: `${apiUrl}/permit_to_work/report`,
      returnType: "json"
    })

    setReportPtw(body)
  }

  useEffect(() => {
    const init = async (modules) => {
      await Promise.all([
        modules.includes("confined_space_smartwatch") && getReportConfnedSpaceSmartwatch(),
        modules.includes("outdoor_smartwatch") && getReportOutdoorSmartwatch(),
        modules.includes("cameras") && getReportCameras(),
        modules.includes("permit_to_work") && getReportPtw()
      ]);

      setLoading(false)
    }

    if (projectInfo != null) {
      const modules = projectInfo["modules"].filter((i) => i["enabled"] === 1).map((i) => i["module"])

      init(modules)
    }
  }, [projectInfo])

  return (<>
    <div className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <div style={{ width: "100%", height: "100%", overflow: "auto" }}>
        {projectInfo &&
          <Stack direction="column" spacing={2}>
            {reportCameras &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_cameras")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div>{reportCameras["online"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div>{reportCameras["offline"]}</div>
                  </div>
                </Stack>
              </Paper>
            }

            {reportConfnedSpaceSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_confinedSpaceSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div>{reportConfnedSpaceSmartwatch["online"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div>{reportConfnedSpaceSmartwatch["offline"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoIosAlert size={30} style={{ color: "red" }}/>
                    <div>{reportConfnedSpaceSmartwatch["alert"]}</div>
                  </div>
                </Stack>
              </Paper>
            }

            {reportOutdoorSmartwatch &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_outdoorSmartwatch")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <HiSignal size={30} style={{ color: "green" }}/>
                    <div>{reportOutdoorSmartwatch["online"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <HiSignalSlash size={30} style={{ color: "grey" }}/>
                    <div>{reportOutdoorSmartwatch["offline"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoIosAlert size={30} style={{ color: "red" }}/>
                    <div>{reportOutdoorSmartwatch["alert"]}</div>
                  </div>
                </Stack>
              </Paper>
            }

            {reportPtw &&
              <Paper elevation={3} style={{ padding: "10px" }}>
                <div style={{ paddingBottom: "10px" }} >{t("module_permitToWork")}</div>
                <Stack direction="row" spacing={2}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#bfbfbf", padding: "10px" }}>
                    <MdOutlinePending size={30} style={{ color: "grey" }}/>
                    <div>{reportPtw["pending"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "#66ff99", padding: "10px" }}>
                    <FiCheckCircle size={30} style={{ color: "green" }}/>
                    <div>{reportPtw["approved"]}</div>
                  </div>
                  
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <MdOutlineTimerOff size={30} style={{ color: "red" }}/>
                    <div>{reportPtw["expired"]}</div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "40px", width: "100px", borderRadius: "5px", backgroundColor: "pink", padding: "10px" }}>
                    <IoClose size={30} style={{ color: "red" }}/>
                    <div>{reportPtw["rejected"]}</div>
                  </div>
                </Stack>
              </Paper>
            }
          </Stack>
        }
      </div>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default Dashboard


