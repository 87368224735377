import react, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { BrowserRouter, Navigate, Route, Routes, useParams } from 'react-router-dom'

import Main from "Pages/Main/Main";

import PermitToWorkList from "Pages/PermitToWork/PermitToWorkList";
import PermitToWorkDetail from "Pages/PermitToWork/PermitToWorkDetail";
import PermitToWorkTemplates from "Pages/PermitToWork/PermitToWorkTemplates";

import AssetList from "Pages/AssetsTracking/AssetList";
import AssetTypes from "Pages/AssetsTracking/AssetTypes";
import AssetCertificates from "Pages/AssetsTracking/AssetCertificates";

import AIDetection from "Pages/AIDetection/AIDetection";
import AIDetectionDetail from "Pages/AIDetection/AIDetectionDetail";

import GasData from "Pages/Gas/GasData";

import VRTrainingRecord from "Pages/VRTraining/VRTrainingRecord";

import CameraList from "Pages/Cameras/CameraList";
import CameraLive from "Pages/Cameras/CameraLive";
import CameraPlayback from "Pages/Cameras/CameraPlayback";

import SmartwatchConfinedSpace from "Pages/Smartwatch/SmartwatchConfinedSpace";
import SmartwatchOutdoor from "Pages/Smartwatch/SmartwatchOutdoor";

import { NotifyToast } from 'Shared/Components/NotifyToast'
import { webUrl } from "Shared/utils";
import SmartlockList from "Pages/Smartlock/SmartlockList";
import Dashboard from "Pages/Main/components/Dashboard";

function App() {
  const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();

  const [start, setStart] = useState(false)

  useEffect(() => {
    let token = null

    const getToken = async () => {
      token = await getAccessTokenSilently()
      sessionStorage.setItem('accessToken', token)

      // const token = await getAccessTokenSilently({
      //   organization: auth0OrgId,
      //   ignoreCache: true,
      // })
    }

    const authenticate = async () => {
      try {
        await getToken()
        setStart(true)

        setInterval(async function()  {
          await getToken()
        }, (token.expires_in - 60)*1000);
      }
      catch (e){
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }

    if (!isLoading) {
      if (!error) {
        if (isAuthenticated) {
          authenticate()
        }
        else {
          loginWithRedirect({
            appState: {
              returnTo: webUrl
            }
          })
        }
      }
      else {
        alert(error)
        logout({ logoutParams: { returnTo: webUrl } })
      }
    }
  }, [isLoading, isAuthenticated])

  return (<>
    {start &&
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path=':organization?/:project?' element={<Main />}>
            <Route index element={<Dashboard />} />

            <Route exact path="cameras/list" element={<CameraList />} />
            <Route exact path="cameras/live" element={<CameraLive />} />
            <Route exact path="cameras/playback" element={<CameraPlayback />} />

            <Route exact path="ai_detection" element={<AIDetection />} />
            <Route path="ai_detection/:camera_id" element={<AIDetectionDetail />} />

            <Route exact path="permit_to_work/list" element={<PermitToWorkList />} />
            <Route path="permit_to_work/list/:template_id/:form_id?" element={<PermitToWorkDetail />} />
            <Route exact path="permit_to_work/templates" element={<PermitToWorkTemplates />} />

            <Route exact path="assets_tracking/list" element={<AssetList />} />
            <Route exact path="assets_tracking/types" element={<AssetTypes />} />
            <Route exact path="assets_tracking/certificates" element={<AssetCertificates />} />

            <Route exact path="gas/data" element={<GasData />} />

            <Route exact path="vr_training/records" element={<VRTrainingRecord />} />
          
            <Route exact path="confined_space_smartwatch/data" element={<SmartwatchConfinedSpace />} />

            <Route exact path="outdoor_smartwatch/data" element={<SmartwatchOutdoor />} />

            <Route exact path="smartlock/list" element={<SmartlockList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    }

    <div>{<NotifyToast/>}</div>
  </>);
}

export default App;
