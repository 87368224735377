import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import 'ol/ol.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import { Auth0Provider } from '@auth0/auth0-react';
import { webUrl } from './Shared/utils';
import 'Shared/i18n'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Auth0Provider
    domain='simplesymmetry-4s.us.auth0.com'
    clientId='HuyZIygzigyuOfYbUYsClvk4c7Zu57tT'
    useRefreshTokens={true}
    cacheLocation='localstorage'
    scope='offline_access'
    authorizationParams={{
      redirect_uri: webUrl,
      audience: '4s-cmp'
    }}
  >
    <App />
  </Auth0Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
