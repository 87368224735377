import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Paper, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import { Button, ButtonGroup, Container, Col, Row, Form } from 'react-bootstrap'
import moment from 'moment';
import { saveAs } from 'file-saver';

import useFetchData from 'Shared/Hooks/useFetchData';
import PopupModal from 'Shared/Components/PopupModal'
import DataTable from 'Shared/Components/DataTable';
import LoadingScreen from 'Shared/Components/LoadingScreen';
import DatePickerComponent from 'Shared/Components/DatePickerComponent';
import { apiUrl, playbackUrl } from 'Shared/utils';

import TimeRangePicker from './components/TimeRangePicker/TimeRangePicker'

const CameraPlayback = () => {
  const { t, i18n } = useTranslation()
  const { organization, project } = useParams()
  const { send } = useFetchData()
  const navigate = useNavigate()

  const [cameras, setCameras] = useState([])
  const [loading, setLoading] = useState(true);

  const [selected, setSelected] = useState()
  const [date, setDate] = useState()

  const [error, setError] = useState(true)
  const [selectedInterval, setSelectedInterval] = useState()
  const [timelineInterval, setTimelineInterval] = useState()
  const [disabledIntervals, setDisabledIntervals] = useState()

  const [playUrl, setPlayUrl] = useState()

  useEffect(() => {
    const init = async () => {
      const body = await send({
        method: "GET",
        url: `${apiUrl}/camera/list`,
        returnType: "json"
      })

      setCameras(body)

      setLoading(false)
    }

    init()
  }, [])

  const onChangeCallback = (e) => {
    setError(e.error)
    e.interval && setSelectedInterval(e.interval)
  }

  const loadData = async (d, p) => {
    if (d == null || p == null) {
      return
    }

    const res = await fetch(`${playbackUrl}/playback/list/${p}/${moment(d).format("YYYY-MM-DD")}`, {
      method: "GET"
    })

    const body = await res.json()

    setDisabledIntervals(
      body.map((i) => ({
        'start': Date.parse(i["start"]),
        'end': Date.parse(i["end"])
      }))
    )

    setSelectedInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
    setTimelineInterval([moment(d).startOf('day').toDate(), moment(d).add(1, "days").startOf('day').toDate()])
  }

  const play = () => {
    if (error) {
      alert(`Error: Invalid Range`)
      return
    }

    const url = `${playbackUrl}/playback/get/${selected}/player?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`
    setPlayUrl(url)
  }

  const download = async () => {
    if (error) {
      alert(`Error: Invalid Range`)
      return
    }
    else if (moment(selectedInterval[1]).diff(moment(selectedInterval[0]), 'minutes', true) > 240) {
      alert(`Error: Maximum download interval: 4 hour`)
      return
    }

    const res = await fetch(`${playbackUrl}/playback/download/${selected}?start_dt=${moment(selectedInterval[0]).format("YYYY-MM-DD_HH-mm-SS")}&end_dt=${moment(selectedInterval[1]).format("YYYY-MM-DD_HH-mm-SS")}`, {
      method: "GET"
    })

    const blob = await res.blob()

    saveAs(blob, `playback.mp4`)
  }

  const updateDate = (d) => {
    setDate(d)

    loadData(d, selected)
  }

  const updatePath = (p) => {
    setSelected(p)

    loadData(date, p)
  }

  return (<>
    <div className="mainContainer" style={{ padding: "15px", backgroundColor: "#F2F2F2" }}>
      <Container className="mainContainer" fluid style={{ width: "100%", height: "100%", borderRadius: "15px", backgroundColor: "white" }}>
        <Row style={{ marginTop: "20px", marginBottom: "20px" }}>
          <Col>
            <Form.Select value={selected} onChange={(e) => updatePath(e.target.value)}>
              <option value="" hidden></option>
              {cameras.map((i) => 
                <option value={i["camera_id"]}>{i["name"]}</option>
              )}
            </Form.Select>
          </Col>

          <Col>
            <DatePickerComponent date={date} type="day" max={new Date()} onChange = {(d) => updateDate(d)} />
          </Col>

          <Col>
            {(selected != null && date != null) && <>
              <ButtonGroup style={{width: "100%"}}>
                {(playUrl == null) ? (<>
                  <Button variant="primary" style={{width: "50%"}} onClick={() => play()}>Play</Button>
                  <Button variant="success" style={{width: "50%"}} onClick={() => download()}>Download</Button>
                </>) : (
                  <Button variant="danger" style={{width: "100%"}} onClick={() => setPlayUrl()}>Stop</Button>
                )}
              </ButtonGroup>
            </>}
          </Col>
        </Row>

        {(selected != null && selectedInterval != null && disabledIntervals != null && timelineInterval != null) && <>
          <Row>
            <Col>
              <TimeRangePicker
                error={error}
                step={60000}
                ticksNumber={120}
                selectedInterval={selectedInterval}
                timelineInterval={timelineInterval}
                disabledIntervals={disabledIntervals}
                onChangeCallback={onChangeCallback}
                setTimelineInterval={setTimelineInterval}
                min={moment(date).startOf('day').toDate()}
                max={moment(date).add(1, "days").startOf('day').toDate()}
                showNow
              />

              <div style={{"padding-left": "30px"}}>{`${moment(selectedInterval[0]).format("YYYY-MM-DD HH:mm:SS")} ~ ${moment(selectedInterval[1]).format("YYYY-MM-DD HH:mm:SS")}`}</div>
            </Col>
          </Row>

          <Row style={{ flexGrow: 1 }}>
            <Col style={{ height: "100%" }}>
              {(playUrl) &&
                <div style={{ width: "100%", height: "100%", flexGrow: 1, overflowX: "hidden", overflowY: "hidden" }}>
                  <iframe src={playUrl} style={{"width": "100%", "height": "100%"}}/>
                </div>
              }
            </Col>
          </Row>
        </>}
      </Container>
    </div>

    <LoadingScreen isOpen={loading} />
  </>)
}

export default CameraPlayback